import React from 'react';
import styled from 'styled-components';
import BlockContent from '@sanity/block-content-to-react';
import axios from 'axios';
import { above, below } from '../styles';
import { useInput } from '../utils/customHooks/useInput';

require('dotenv').config({
  path: `.env.${process.env.NODE_ENV}`,
});

const formUrl = process.env.GATSBY_SLACK_CONTACT_FORM_WEBHOOK;

const Root = styled.div`
  background-color: ${props => props.backgroundColor};
  display: flex;
  flex-wrap: wrap-reverse;
  padding-left: 28px;
  padding-right: 28px;
  padding-bottom: 100px;

  @media ${above.sm} {
    padding-left: 50px;
    padding-right: 50px;
  }

  @media ${above.lg} {
    padding-left: 100px;
    padding-right: 100px;
    padding-bottom: 200px;
  }

  @media ${above.xl} {
    padding-left: 140px;
    padding-right: 140px;
  }

  @media ${above.xxl} {
    padding-left: 240px;
    padding-right: 240px;
  }
`;

const FormContainer = styled.div`
  display: block;
  width: 50%;
  padding-top: 314px;

  @media ${below.xl} {
    width: 100%;
    padding-top: 50px;
  }
`;

const TextContainer = styled.div`
  display: block;
  width: 50%;
  padding-top: 135px;
  padding-left: 50px;

  @media ${below.xl} {
    width: 100%;
    padding-top: 230px;
    padding-left: 0px;
  }
`;
const HeaderText = styled.div`
  width: 105%;
  font-size: 7.5rem;
  line-height: 1.25;
  font-weight: bold;

  @media ${below.md} {
    font-size: 4.6rem;
    line-height: 1.26;
  }
`;

const SubHeaderTextWrapper = styled.div`
  width: 90%;
  margin-top: 20px;
  margin-left: 75px;

  @media ${below.md} {
    width: 100%;
    margin-left: 7px;
  }
`;

const SubHeading = styled.p`
  font-size: 3.6rem;
  line-height: 1.61;

  @media ${below.md} {
    font-size: 2.4rem;
    line-height: 1.625;
    margin-bottom: 0px;
  }

  a {
    color: ${props => props.theme.color.green};
  }
`;

const Form = styled.form``;

const Input = styled.input.attrs(props => ({
  type: 'text',
  placeholder: props.defaultValue,
}))`
  padding: 22px 20px 18px 20px;
  margin-top: 30px;
  width: 100%;
  font-size: 1.8rem;
  border: none;
  color: ${props => props.theme.color.grey};
  &:focus {
    outline-color: ${props => props.theme.color.green};
  }
  background-color: white;
  @media ${below.md} {
    margin-top: 40px;
  }
`;

const ShortInput = styled(Input)`
  width: calc(50% - 15px);
  @media ${below.md} {
    width: 100%;
  }
`;

const FirstNameInput = styled(ShortInput)`
  margin-right: 30px;

  @media ${below.md} {
    margin-right: 0px;
    margin-top: 40px;
  }
`;

const JobTitleInput = styled(FirstNameInput)`
  @media ${below.md} {
    margin-top: 40px;
  }
`;

const PhoneInput = styled(ShortInput)`
  margin-right: 30px;

  @media ${below.md} {
    margin-right: 0px;
  }
`;

const TextArea = styled.textarea.attrs(props => ({
  type: 'text',
  placeholder: props.defaultValue,
}))`
  padding: 22px 20px 0px 20px;
  margin-top: 30px;
  margin-right: 30px;
  width: 100%;
  font-size: 1.8rem;
  border: none;
  &:focus {
    outline-color: ${props => props.theme.color.green};
  }
  @media ${below.md} {
    margin-top: 40px;
  }
`;

const SelectContainer = styled.div`
  display: inline-block;
  type: text;
  padding: 22px 20px 18px 20px;
  margin-top: 30px;
  margin-right: 0px;
  width: calc(50% - 15px);
  font-size: 1.8rem;
  border: none;
  background-color: white;
  color: red;

  &:focus {
    outline-color: ${props => props.theme.color.green};
  }
  @media ${below.md} {
    margin-top: 40px;
    width: 100%;
  }
`;

const StyledSelect = styled.select`
  border: none;
  width: 100%;
  &:focus {
    outline: 0;
  }
`;

const StyledOption = styled.option`

  }
`;

const SubmitButton = styled.button`
  padding: 22px 20px 18px 2px;
  margin-top: 40px;
  margin-right: 30px;
  width: 100%;
  font-size: 1.8rem;
  background-color: ${props => props.theme.color.green};
  color: white;
  border: none;
  text-align: center;
  cursor: pointer;
  &:focus {
    outline-color: ${props => props.theme.color.white};
  }
`;

const ContactForm = ({ block }) => {
  const { backgroundColor, header, contactFormSubTitle } = block;

  const { bind: firstName, reset: resetFirstName } = useInput('');
  const { bind: lastName, reset: resetLastName } = useInput('');
  const { bind: jobTitle, reset: resetJobTitle } = useInput('');
  const { bind: budget, reset: resetBudget } = useInput('');
  const { bind: email, reset: resetEmail } = useInput('');
  const { bind: webSite, reset: resetWebSite } = useInput('');
  const { bind: phone, reset: resetPhone } = useInput('');
  const { bind: company, reset: resetCompany } = useInput('');
  const { bind: message, reset: resetMessage } = useInput('');

  const handleSubmit = e => {
    e.preventDefault();

    const formData = {
      firstName: firstName.value,
      lastName: lastName.value,
      jobTitle: jobTitle.value,
      budget: budget.value,
      email: email.value,
      webSite: webSite.value,
      phone: phone.value,
      company: company.value,
      message: message.value,
    };

    const messageBlocks = [
      {
        type: 'section',
        text: {
          type: 'mrkdwn',
          text: `*Name:* ${formData.firstName} ${formData.lastName} / ${formData.jobTitle}`,
        },
      },
      {
        type: 'section',
        text: {
          type: 'mrkdwn',
          text: `:email: *Email:* ${formData.email}`,
        },
      },
      {
        type: 'section',
        text: {
          type: 'mrkdwn',
          text: `:computer: *Website:* ${formData.webSite}`,
        },
      },
      {
        type: 'section',
        text: {
          type: 'mrkdwn',
          text: `:phone: *Phone:* ${formData.phone}`,
        },
      },
      {
        type: 'section',
        text: {
          type: 'mrkdwn',
          text: `:moneybag: *Budget:* ${formData.budget}`,
        },
      },
      {
        type: 'section',
        text: {
          type: 'mrkdwn',
          text: `*Company:* ${formData.company}`,
        },
      },
      {
        type: 'section',
        text: {
          type: 'mrkdwn',
          text: `*Message:* ${formData.message}`,
        },
      },
    ];

    const messageToSlack = {
      text: 'Incoming Project Request :tada:',
      blocks: [
        {
          type: 'header',
          text: {
            type: 'plain_text',
            text: `:tada: Incoming Project Request - ${new Date().toLocaleString()}`,
            emoji: true,
          },
        },
        ...messageBlocks,
      ],
    };

    axios({
      method: 'POST',
      url: formUrl,
      data: JSON.stringify(messageToSlack),
    })
      .then(() => {
        resetFirstName();
        resetLastName();
        resetJobTitle();
        resetBudget();
        resetEmail();
        resetWebSite();
        resetPhone();
        resetCompany();
        resetMessage();
      })
      .catch(error => alert(error));
    alert('Your message has been submitted!');
  };

  function createMarkup(mark) {
    return { __html: mark.rawHTML };
  }

  function renderHTML({ mark }) {
    return <span dangerouslySetInnerHTML={createMarkup(mark)} />;
  }

  return (
    <Root backgroundColor={backgroundColor && backgroundColor.hex}>
      <FormContainer>
        <Form
          name="Contact Form"
          netlify-honeypot="bot-field"
          data-netlify="true"
          onSubmit={handleSubmit}
        >
          <input type="hidden" name="form-name" value="Contact Form" />
          <FirstNameInput
            required
            name="firstName"
            type="text"
            defaultValue="First Name*"
            {...firstName}
          />
          <ShortInput
            required
            name="lastName"
            type="text"
            defaultValue="Last Name*"
            {...lastName}
          />
          <JobTitleInput
            name="jobTitle"
            type="text"
            defaultValue="Job Title"
            {...jobTitle}
          />
          <SelectContainer>
            <StyledSelect id="budget" name="budget" required {...budget}>
              <StyledOption value="" disabled selected>
                Select a budget
              </StyledOption>
              <StyledOption value="Less than $25,000">
                Less than $25,000
              </StyledOption>
              <StyledOption value="$25,000 to $75,000">
                $25,000 to $75,000
              </StyledOption>
              <StyledOption value="$75,000 to $150,000">
                $75,000 to $150,000
              </StyledOption>
              <StyledOption value="$150,000 to $300,000">
                $150,000 to $300,000
              </StyledOption>
              <StyledOption value="$300,000 to $500,000">
                $300,000 to $500,000
              </StyledOption>
              <StyledOption value="More than $500,000">
                More than $500,000
              </StyledOption>
            </StyledSelect>
          </SelectContainer>
          <Input
            required
            name="email"
            type="text"
            defaultValue="Email*"
            {...email}
          />
          <Input
            required
            name="webSite"
            type="text"
            defaultValue="Company Website*"
            {...webSite}
          />
          <PhoneInput
            required
            name="phone"
            type="text"
            defaultValue="Phone*"
            {...phone}
          />
          <ShortInput
            required
            name="company"
            type="text"
            defaultValue="Company*"
            {...company}
          />
          <TextArea
            required
            name="message"
            type="text"
            defaultValue="Message*"
            {...message}
          />
          <SubmitButton type="submit">Send</SubmitButton>
        </Form>
      </FormContainer>
      <TextContainer>
        <HeaderText>{header}</HeaderText>
        <SubHeaderTextWrapper>
          <BlockContent
            blocks={contactFormSubTitle}
            serializers={{
              types: {
                block: SubHeading,
              },
              marks: {
                rawHTML: renderHTML,
              },
            }}
          />
        </SubHeaderTextWrapper>
      </TextContainer>
    </Root>
  );
};

export default ContactForm;
