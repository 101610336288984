import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useWindowSize } from 'react-use';
import TrackVisibility from 'react-on-screen';
import CTALink from '../elements/CTALink';
import { above, below, BREAKPOINTS } from '../styles';
import { Container, FluidImg, Paragraph } from '../elements';

const TimelineWrapper = styled.section`
  position: relative;
  background: ${props =>
    props.backgroundColor ? props.theme.color.grey.superLight : 'none'};
  padding-top: ${props => (props.padding ? '110px' : '0px')};
  padding-bottom: ${props => (props.padding ? '110px' : '0px')};

  @media ${above.lg} {
    padding-top: ${props => (props.padding ? '100px' : '0px')};
    padding-bottom: ${props => (props.padding ? '100px' : '0px')};
  }
`;

const Heading = styled.h3`
  font-size: 5.2rem;
  line-height: 1.26;

  @media ${below.md} {
    margin-bottom: 100px;
    width: 50%;
    line-height: 66px;
  }

  @media ${above.lg} {
    font-size: 7.5rem;
  }
`;

const Item = styled.div`
  @media ${above.lg} {
    display: flex;
  }
`;

const Title = styled.h4`
  font-size: 3.6rem;
`;

const TextBlock = styled.div`
  width: 90%;
  padding-left: 24px;

  @media ${above.sm} {
    padding-left: 50px;
  }

  @media ${above.lg} {
    padding-left: 85px;
  }
`;

const Left = styled.div`
  max-width: 300px;
  margin-left: 60px;
  margin-bottom: 20px;

  @media ${above.lg} {
    max-width: 450px;
    margin-bottom: 0;
    padding-right: 50px;
    margin-left: auto;
    width: 45%;
  }
`;

const Right = styled.div`
  display: flex;
  padding-bottom: 140px;

  @media ${above.lg} {
    width: 55%;
    padding-bottom: 155px;
  }
`;

const StyledCTALink = styled(CTALink)`
  justify-content: flex-start;
`;

const Dot = styled.div`
  position: relative;
  z-index: 10;
  background: ${props => props.theme.color.green};
  border-radius: 50%;
  width: 35px;
  height: 35px;
`;

const StyledFluidImg = styled(FluidImg)`
  @media ${above.lg} {
    margin-left: auto;
  }
`;

const ProgressBar = styled.div`
  overflow: hidden;
  position: absolute;
  top: ${props => props.progressBarOffsetTop}px;
  left: ${props => props.progressBarOffsetLeft};
  transform: translateY(17px);
  width: 5px;
  background: ${props => props.theme.color.grey.medium};
  height: ${props => props.progressBarHeight}px;
  border-radius: 2px;
  opacity: 0.3;
`;

const ProgressBarFill = styled.div`
  position: absolute;
  transition: height 0.5s ease;
  top: 0;
  width: 5px;
  opacity: 0;
  background: ${props => props.theme.color.green};
`;

const VisibilityContent = styled.div``;

const VisibilityContainer = styled.div`
  //line-height: 0;
  @media ${above.md} {
    ${VisibilityContent} {
      transition-property: opacity;
      transition-duration: 0.5s;
      transition-timing-function: ease;
      transition-delay: ${props => (props.isVisible ? '0.5s' : '0')};
      //opacity: ${props => (props.isVisible ? '1' : '0')};
    }
    ${Dot} {
      transition-property: transform;
      transition-duration: 0.3s;
      transition-timing-function: cubic-bezier(.82, .1, .14, 1.52);
      transform: scale(${props => (props.isVisible ? '1' : '0')});
    }
    ${Left} {
      transition-property: opacity transform;
      transition-duration: 0.5s;
      transition-timing-function: ease;
      transition-delay: 400ms;
      transform: translateY(${props => (props.isVisible ? '0px' : '20px')});
      opacity: ${props => (props.isVisible ? '1' : '0')};
    }
    ${TextBlock} {
      transition-property: opacity transform;
      transition-duration: 0.5s;
      transition-timing-function: ease;
      transition-delay: 200ms;
      transform: translateY(${props => (props.isVisible ? '0px' : '20px')});
      opacity: ${props => (props.isVisible ? '1' : '0')};
    }
  }
`;

const Timeline = ({ block: { heading, items, padding, backgroundColor } }) => {
  const { width } = useWindowSize();
  const isMobile = width < BREAKPOINTS.lg;

  const [progressBarOffsetTop, setProgressBarOffsetTop] = useState(0);
  const [progressBarOffsetLeft, setProgressBarOffsetLeft] = useState(0);

  const [progressBarHeight, setProgressBarHeight] = useState(0);
  const [progressBarFillHeight, setProgressBarFillHeight] = useState(0);

  // For handling scroll
  useEffect(() => {
    const handleScroll = () => {
      // how many pixel is scrolled from top of window
      const windowScrolled = window.pageYOffset;
      // how far is the timeline component from the top of window
      const timelinePosition = document.querySelector('.timeline-wrapper')
        .offsetTop;
      /**
       * The bigger this number, the more the user needs to scroll before
       * it starts filling the progress bar. Set it to a bigger number
       * so on mobile the fill effect can be seen
       */
      const threshold = timelinePosition / 1.5;

      if (windowScrolled >= threshold) {
        // Update the fill height
        if (progressBarHeight >= windowScrolled - threshold) {
          setProgressBarFillHeight(windowScrolled - threshold);
        }
      }

      // The fill should never be shorter than the bar itself
      if (windowScrolled < threshold) {
        setProgressBarFillHeight(0);
      }
      // The fill should never be longer than the bar itself
      if (progressBarHeight < windowScrolled - threshold) {
        setProgressBarFillHeight(progressBarHeight);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  });

  /** Fires on screen resizes */
  useEffect(() => {
    const firstDot = document.querySelector('.dot');

    setProgressBarOffsetTop(firstDot.offsetTop);
    setProgressBarOffsetLeft(`${firstDot.offsetLeft + 15}px`);
  });

  /**
   * Calculate the distance between the first dot and the last one
   * Then use this value to set the progress bar height
   */
  useEffect(() => {
    const textBlocks = Array.from(document.querySelectorAll('.dot'));

    const firstDotOffsetTop = textBlocks[0].getBoundingClientRect().top;
    const lastDotOffsetTop = textBlocks[
      textBlocks.length - 1
    ].getBoundingClientRect().top;

    // To prevent fill longer than the bar height during screen resize
    if (progressBarHeight < progressBarFillHeight) {
      setProgressBarFillHeight(progressBarHeight);
    }

    // Update bar height as screen resizes
    setProgressBarHeight(lastDotOffsetTop - firstDotOffsetTop);
  });

  return (
    <TimelineWrapper
      padding={padding}
      backgroundColor={backgroundColor}
      className="timeline-wrapper"
    >
      <Container>
        {heading && <Heading>{heading}</Heading>}
        <ProgressBar
          progressBarHeight={progressBarHeight}
          progressBarOffsetTop={progressBarOffsetTop}
          progressBarOffsetLeft={progressBarOffsetLeft}
        >
          <ProgressBarFill style={{ height: progressBarFillHeight }} />
        </ProgressBar>

        {items &&
          items.length > 0 &&
          items.map(item => (
            <TrackVisibility once={true} offset={450}>
              <VisibilityContainer>
                <VisibilityContent>
                  <Item key={item._key}>
                    <Left>
                      {item.icon && <StyledFluidImg asset={item.icon.asset} />}
                    </Left>
                    <Right>
                      <Dot className="dot" />
                      <TextBlock>
                        {item.title && <Title>{item.title}</Title>}
                        {item.text && <Paragraph text={item.text} />}
                        {item.ctaUrl && item.ctaUrlText && (
                          <StyledCTALink
                            center
                            url={'/' + item.ctaUrl.slug.current}
                            text={item.ctaUrlText}
                            darkMode
                          />
                        )}
                      </TextBlock>
                    </Right>
                  </Item>
                </VisibilityContent>
              </VisibilityContainer>
            </TrackVisibility>
          ))}
      </Container>
    </TimelineWrapper>
  );
};

export default Timeline;
