import React, { useEffect, useState } from 'react';
import { renderToString } from 'react-dom/server';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { get } from 'lodash';
import BlockContent from '@sanity/block-content-to-react';
import readTimeEstimate from 'read-time-estimate';
import RichTextYouTube from './RichTextYoutube';
import Table from './Table';
import { FluidImg } from '../elements';
import { imageUrlFor, buildImageObj } from '../utils/image-url';
import { above, below } from '../styles';
import checkPropsEquality from '../utils/checkPropsEquality';

const Content = styled.div`
  height: auto;
  margin-top: auto;
`;
const Title = styled.h3`
  font-weight: bold;
  line-height: 1.38;
  font-size: 2.4rem;
  display: inline;

  background-image: linear-gradient(
    ${props => props.theme.color.green},
    ${props => props.theme.color.green}
  );
  background-position: 0% 87%;
  background-repeat: no-repeat;
  transition: background-size 0.3s;

  @media ${above.lg} {
    font-size: 3.6rem;
  }
`;
const Info = styled.div`
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 14px;
`;

const Details = styled.div`
  margin-top: 10px;
  @media ${below.md} {
    margin-top: 10px;
  }
  display: flex;
  align-items: center;
`;

const ReadTime = styled.p`
  margin-bottom: 10px;
  font-size: 1.6rem;
  line-height: 1;
`;

const Author = styled.p`
  font-weight: bold;
  font-size: 1.6rem;
  line-height: 1;
  margin-bottom: 0;
  transition: 300ms;
`;

const ImgWrapper = styled.div`
  border-radius: 50%;
  overflow: hidden;
  height: 60px;
  max-width: 60px;
  transition: 300ms;
`;

const Card = styled.div`
  display: flex;
  width: 100%;
  padding: 30px;
  break-inside: avoid-column;
  cursor: pointer;
  transition: 300ms;
  transform: ${props => (props.isHover ? 'scale(1.02)' : 'scale(1)')};
  box-shadow: 0 0 60px 0px
    ${props => (props.isHover ? 'rgba(32,40,70,0.1)' : 'rgba(0,0,0,0)')};
  background-color: ${props =>
    props.backgroundcolor ? props.backgroundcolor : props.theme.color.white};
  color: ${props =>
    props.backgroundcolor
      ? props.theme.color.white
      : props.theme.color.blue.lightNavy};
  &:visited {
    color: ${props =>
      props.backgroundcolor
        ? props.theme.color.white
        : props.theme.color.blue.lightNavy};
  }
  &::before {
    content: '';
    width: 1px;
    margin-left: -1px;
    float: left;
    height: 0;
    padding-top: 0%;
  }
  &::after {
    /* to clear float */
    content: '';
    display: table;
    clear: both;
  }

  @media ${below.md} {
    padding: 60px 45px 45px;
  }

  @media ${above.md} {
    padding: 40px;
    &::before {
      padding-top: 100%;
    }
  }

  ${Title} {
    background-size: ${props => (props.isHover ? '100% 5px' : '0% 5px')};
    background-image: linear-gradient(
      ${props =>
        props.backgroundcolor
          ? props.theme.color.blue.lightNavy
          : props.theme.color.green},
      ${props =>
        props.backgroundcolor
          ? props.theme.color.blue.lightNavy
          : props.theme.color.green}
    );
  }

  ${Author} {
    color: ${props =>
      props.backgroundcolor
        ? props.isHover
          ? props.theme.color.blue.lightNavy
          : props.theme.color.white
        : props.isHover && props.theme.color.green};
  }

  ${ImgWrapper} {
    box-shadow: ${props =>
      props.isHover &&
      `0 0 0 3px ${
        props.backgroundcolor
          ? props.theme.color.blue.lightNavy
          : props.theme.color.green
      }`};
  }
`;

const PostTeaserCard = props => {
  const {
    post: { author, title, slug },
    backgroundColor,
  } = props;
  const [isHover, setIsHover] = useState(false);
  const [readTime, setReadTime] = useState(0);
  const handleMouseEnter = () => {
    setIsHover(true);
  };
  const handleMouseLeave = () => {
    setIsHover(false);
  };

  const shortenedString =
    title.length > 100 ? title.substring(0, 100) + ' ...' : title;
  const slugPrefix = '/articles/';

  function createMarkup(mark) {
    return { __html: mark.rawHTML };
  }

  function renderHTML({ mark }) {
    return <div dangerouslySetInnerHTML={createMarkup(mark)} />;
  }

  const serializer = {
    types: {
      defaultImage: props => (
        <FluidImg
          asset={props.node.asset}
          alt={props.node.alt}
          wrapperStyle={{
            maxWidth: props.node.asset.metadata.dimensions.width,
            margin: '0 auto',
          }}
        />
      ),
      youtube: props => (
        <RichTextYouTube url={props.node.url} centered={props.node.centered} />
      ),
      tableBlock: props => <Table block={props.node} />,
    },
    marks: {
      rawHTML: renderHTML,
    },
  };

  useEffect(() => {
    const content = get(
      props,
      'post._rawContentBlocks[0].content',
      get(props, 'post.contentBlocks[0].content')
    );
    if (content) {
      const htmlString = renderToString(
        <BlockContent blocks={content} serializers={serializer} />
      );
      const { duration } = readTimeEstimate(htmlString, 275, 12, 500, [
        'img',
        'Image',
      ]);
      setReadTime(duration);
    } else {
      setReadTime(0);
    }
  }, []);

  return (
    <Card
      as={Link}
      to={slugPrefix + slug.current}
      backgroundcolor={backgroundColor && backgroundColor.hex}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      ishover={isHover ? 1 : 0}
    >
      <Content>
        <Title
          backgroundcolor={backgroundColor && backgroundColor.hex}
          dangerouslySetInnerHTML={{ __html: shortenedString }}
        />
        <Details>
          <ImgWrapper>
            {get(author, 'image') && (
              <img
                src={imageUrlFor(buildImageObj(author.image))
                  .width(60)
                  .auto('format')
                  .url()}
                alt={author.image.alt}
              />
            )}
          </ImgWrapper>
          <Info>
            <ReadTime>{Math.ceil(readTime)} min read</ReadTime>
            {author && (
              <Author backgroundcolor={backgroundColor && backgroundColor.hex}>
                {author.name}
              </Author>
            )}
          </Info>
        </Details>
      </Content>
    </Card>
  );
};

export default React.memo(PostTeaserCard, checkPropsEquality);
