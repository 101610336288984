import React from 'react';
import styled from 'styled-components';
import TrackVisibility from 'react-on-screen';
import { above, below } from '../styles';
import CTALink from '../elements/CTALink';

const Card = styled.div`
  padding: 30px;
  background-color: ${props => props.theme.color.green};
  display: inline-flex;
  width: 100%;
  align-items: flex-end;

  @media ${below.md} {
    padding: 45px;
  }

  @media ${above.md} {
    margin-bottom: 0;
    padding: 40px;
  }

  &::before {
    content: '';
    width: 1px;
    margin-left: -1px;
    float: left;
    height: 0;
    padding-top: 100%;
  }
  &::after {
    /* to clear float */
    content: '';
    display: table;
    clear: both;
  }
`;

const Title = styled.h3`
  font-weight: bold;
  line-height: 1.67;
  font-size: 2.4rem;
  color: ${props => props.theme.color.white};
  @media ${above.lg} {
    font-size: 3.6rem;
    line-height: 1.38;
  }
  margin-bottom: 15px;
`;

const VisibilityContent = styled.div``;

const VisibilityContainer = styled.div`
  line-height: 0;
  @media ${above.md} {
    transition-property: clip-path transform box-shadow;
    transition-duration: 0.5s;
    transition-timing-function: ease;
    transition-delay: ${props => (props.isVisible ? '0s' : '0.5s')};
    //transform-origin: 0 0;
    overflow: hidden;
    background-color: ${props => props.theme.color.green};
    clip-path: polygon(
      -20% -40%,
      ${props => (props.isVisible ? '140%' : '0%')} 0,
      ${props => (props.isVisible ? '120%' : '0%')} 120%,
      -20% 120%
    );
    ${VisibilityContent} {
      transition-property: opacity;
      transition-duration: 0.5s;
      transition-timing-function: ease;
      transition-delay: ${props => (props.isVisible ? '0.5s' : '0')};
      opacity: ${props => (props.isVisible ? '1' : '0')};
    }
  }
`;

const PlainTile = tile => {
  const { content } = tile;
  return (
    <TrackVisibility once={true} offset={100}>
      <VisibilityContainer>
        <VisibilityContent>
          <Card>
            <div>
              <Title>{content.heading}</Title>
              <CTALink
                url={content.ctaUrl[0].slug.current}
                text={content.ctaUrlText}
              />
            </div>
          </Card>
        </VisibilityContent>
      </VisibilityContainer>
    </TrackVisibility>
  );
};

export default PlainTile;
